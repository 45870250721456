<template>
  <div>
    <p class="title is-3">Administrare</p>
    <p class="subtitle is-5">Persoane Juridice</p>

    <TabelPersoane tip="pj" />
  </div>
</template>

<script>
import TabelPersoane from "./TabelPersoane";

export default {
  name: "PersoaneJuridice",
  components: {
    TabelPersoane,
  },
};
</script>

<style scoped>

</style>